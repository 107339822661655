import React from 'react';
import PropTypes from 'prop-types';

function Panel({
  title,
  content,
  footerContent,
  cssClasses,
  hasNoContentPadding,
  hasBackgroundFade
}) {
  return (
    <div className={`panel ${cssClasses}`}>
      {title && (
        <div className="panel-header">
          <h2>{title}</h2>
        </div>
      )}
      <div
        className={`panel-container ${hasNoContentPadding ? 'p-0' : ''} ${
          hasBackgroundFade ? 'faded-bg' : ''
        }`}
      >
        {content || (<>&nbsp;</>)}
      </div>
      {footerContent && (
        <div className="panel-container btn-container border-faded border-left-0 border-right-0 border-bottom-0 bg-faded">
          {footerContent}
        </div>
      )}
    </div>
  );
}

Panel.propTypes = {
  title: PropTypes.string,
  content: PropTypes.node,
  footerContent: PropTypes.node,
  cssClasses: PropTypes.string,
  hasNoContentPadding: PropTypes.bool,
  hasBackgroundFade: PropTypes.bool
};

export default Panel;
