/*---------------------------------------------------------------------------------------------*
 * Summary                                                                                     *
 *---------------------------------------------------------------------------------------------*
 Sets up an http request with the given options. We also contain request definitions here for
 system wide use. We can invoke the request definition and then pass it to the request function.

  Flow as follows:
    standard: httpRequest(options)
    with definition: httpRequest(api(params))
                                                                                                
 */

import { useState } from 'react';
import httpRequest from '../../api/http';

const MOCK_DELAY = 1000;

function useRequest(request) {
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState();
  const [error, setError] = useState();

  async function makeRequest() {
    setLoading(true);
    try {
      const r = await httpRequest(request);
      if (MOCK_DELAY) {
        setTimeout(() => {
          setResponse(r);
          setLoading(false);
        }, MOCK_DELAY);
      } else {
        setResponse(r);
        setLoading(false);
      }
    } catch (e) {
      console.error(e);
      setError(e);
      setLoading(false);
    }
  }

  return {
    makeRequest,
    loading,
    error,
    response
  };
}

/*---------------------------------------------------------------------------------------------*
 * Requests                                                                                    *
 *---------------------------------------------------------------------------------------------*/
export const interpretDGARequest = (params) => ({
  url: `http://localhost:55001/dga/interpret?H2=${params.hydrogenPPM}&CH4=${params.methanePPM}&C2H6=${params.ethanePPM}&C2H4=${params.ethylenePPM}&C2H2=${params.acetylenePPM}&C3H8=${params.propanePPM}&C3H6=${params.propylenePPM}&CO=${params.carbonMonoxidePPM}&CO2=${params.carbonDioxidePPM}&O2=${params.oxygenPPM}&N2=${params.nitrogenPPM}`,
  method: 'GET'
});

export default useRequest;
