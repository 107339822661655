/*---------------------------------------------------------------------------------------------*
 * Summary                                                                                     *
 *---------------------------------------------------------------------------------------------*
 
 A hook to capture form input and update state based on the input field's name property. 

 Additionally, validate inputs on change.
 
 */

import { useState, useEffect } from 'react';
import defaultRules from '../validation/rules';

/*---------------------------------------------------------------------------------------------*
 * Hook                                                                                        *
 *---------------------------------------------------------------------------------------------*/
function useForm({ validation }) {
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState({});
  const [isValidForm, setIsValidForm] = useState(true);
  const [isDirtyForm, setIsDirtyForm] = useState(false);

  const rules = { ...defaultRules, ...validation.rules };
  const ruleAssignments = { ...validation.assignments };

  const validate = (event) => {
    const inputName = event.target.name;
    const inputValue = event.target.value;
    // Validate input if we received a rule declaration
    if (ruleAssignments[inputName]) {
      ruleAssignments[inputName].every((ruleToValidate) => {
        let isValid = true;
        if (!ruleToValidate || !rules[ruleToValidate]) {
          return isValid; // fail silently for now
        }
        isValid = rules[ruleToValidate].test(inputValue);
        if (!isValid) {
          setErrors({ ...errors, [inputName]: rules[ruleToValidate].errorMessage });
          return isValid;
        }
        // If we get here, we don't have a validation error. Delete previous entry if there was one
        const updatedErrors = { ...errors };
        delete updatedErrors[inputName];
        setErrors(updatedErrors);
        return isValid;
      });
    }
  };

  const handleChange = (event) => {
    event.persist();
    setValues({ ...values, [event.target.name]: event.target.value });
    setIsDirtyForm(true);
    validate(event);
  };

  const resetForm = () => {
    setValues({});
    setErrors({});
    setIsDirtyForm(false);
  };

  // Check to see if we have any errors in the form
  useEffect(() => {
    setIsValidForm(Object.entries(errors).length === 0 && errors.constructor === Object);
  }, [errors]);

  return {
    handleChange,
    errors,
    values,
    resetForm,
    isValidForm,
    isDirtyForm
  };
}

export default useForm;
