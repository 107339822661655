/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import Panel from '../Panel';

import './calculator.scss';

function Values({
  values,
  errors,
  isValidForm,
  formIsLoading,
  handleChange,
  handleSubmit,
  handleReset
}) {
  return (
    <Panel
      content={
        <div className="calculator">
          <form className="calculator-form">
            <ul>
              <li>
                <label htmlFor="hydrogenPPM">
                  Hydrogen (H<sub>2</sub>):
                  <div className="error-message">{errors.hydrogenPPM}</div>
                </label>
                <input
                  type="text"
                  name="hydrogenPPM"
                  className={`form-control ${errors.hydrogenPPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.hydrogenPPM || ''}
                />
              </li>
              <li>
                <label htmlFor="methanePPM">
                  Methane (CH<sub>4</sub>):
                  <div className="error-message">{errors.methanePPM}</div>
                </label>
                <input
                  type="text"
                  name="methanePPM"
                  className={`form-control ${errors.methanePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.methanePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="ethanePPM">
                  Ethane (C<sub>2</sub>H<sub>6</sub>):
                  <div className="error-message">{errors.ethanePPM}</div>
                </label>
                <input
                  type="text"
                  name="ethanePPM"
                  className={`form-control ${errors.ethanePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.ethanePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="ethylenePPM">
                  Ethylene (C<sub>2</sub>H<sub>4</sub>):
                  <div className="error-message">{errors.ethylenePPM}</div>
                </label>
                <input
                  type="text"
                  name="ethylenePPM"
                  className={`form-control ${errors.ethylenePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.ethylenePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="acetylenePPM">
                  Acetylene (C<sub>2</sub>H<sub>2</sub>):
                  <div className="error-message">{errors.acetylenePPM}</div>
                </label>
                <input
                  type="text"
                  name="acetylenePPM"
                  className={`form-control ${errors.acetylenePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.acetylenePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="propanePPM">
                  Propane (C<sub>3</sub>H<sub>8</sub>):
                  <div className="error-message">{errors.propanePPM}</div>
                </label>
                <input
                  type="text"
                  name="propanePPM"
                  className={`form-control ${errors.propanePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.propanePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="propylenePPM">
                  Propylene (C<sub>3</sub>H<sub>6</sub>):
                  <div className="error-message">{errors.propylenePPM}</div>
                </label>
                <input
                  type="text"
                  name="propylenePPM"
                  className={`form-control ${errors.propylenePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.propylenePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="carbonMonoxidePPM">
                  Carbon monoxide (CO):
                  <div className="error-message">{errors.carbonMonoxidePPM}</div>
                </label>
                <input
                  type="text"
                  name="carbonMonoxidePPM"
                  className={`form-control ${errors.carbonMonoxidePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.carbonMonoxidePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="carbonDioxidePPM">
                  Carbon dioxide (CO<sub>2</sub>):
                  <div className="error-message">{errors.carbonDioxidePPM}</div>
                </label>
                <input
                  type="text"
                  name="carbonDioxidePPM"
                  className={`form-control ${errors.carbonDioxidePPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.carbonDioxidePPM || ''}
                />
              </li>
              <li>
                <label htmlFor="oxygenPPM">
                  Oxygen (O<sub>2</sub>):
                  <div className="error-message">{errors.oxygenPPM}</div>
                </label>
                <input
                  type="text"
                  name="oxygenPPM"
                  className={`form-control ${errors.oxygenPPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.oxygenPPM || ''}
                />
              </li>
              <li>
                <label htmlFor="nitrogenPPM">
                  Nitrogen (N<sub>2</sub>):
                  <div className="error-message">{errors.nitrogenPPM}</div>
                </label>
                <input
                  type="text"
                  name="nitrogenPPM"
                  className={`form-control ${errors.nitrogenPPM ? 'error' : ''}`}
                  onChange={handleChange}
                  value={values.nitrogenPPM || ''}
                />
              </li>
            </ul>
          </form>
        </div>
      }
      footerContent={
        <>
          <button type="button" className="btn btn-secondary" onClick={handleReset}>
            Reset
          </button>
          <button
            type="button"
            className="btn btn-success m-l-5"
            onClick={handleSubmit}
            disabled={!isValidForm || formIsLoading}
          >
            Submit
          </button>
        </>
      }
      hasBackgroundFade
    />
  );
}

Values.propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  isValidForm: PropTypes.bool,
  formIsLoading: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleReset: PropTypes.func
};

export default Values;
