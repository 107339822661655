import React, { useState } from 'react';

import SubHeader from '../SubHeader';
import Values from './Values';
import Results from './Results';
import useForm from '../../extensions/hooks/useForm';
import useRequest, { interpretDGARequest } from '../../extensions/hooks/useRequest';

function Calculator() {
  const [isClean, setIsClean] = useState(true);
  const { values, errors, isValidForm, handleChange, resetForm } = useForm({
    validation: {
      assignments: {
        hydrogenPPM: ['number'],
        methanePPM: ['number'],
        ethanePPM: ['number'],
        ethylenePPM: ['number'],
        acetylenePPM: ['number'],
        propanePPM: ['number'],
        propylenePPM: ['number'],
        carbonMonoxidePPM: ['number'],
        carbonDioxidePPM: ['number'],
        oxygenPPM: ['number'],
        nitrogenPPM: ['number']
      }
    }
  });
  const { loading: dgaLoading, response: dgaResults, makeRequest: requestDGA } = useRequest(
    interpretDGARequest(values)
  );

  const handleReset = () => {
    resetForm();
    setIsClean(true);
  };

  const handleSubmit = () => {
    if (isValidForm) {
      requestDGA();
      setIsClean(false);
    } else {
      console.log('invalid form');
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <SubHeader
            title="DGA Calculator"
            subtitle="Enter gas values to see interpretations and recommendations"
          />
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12 col-md-3">
          <Values
            values={values}
            errors={errors}
            isValidForm={isValidForm}
            formIsLoading={dgaLoading}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            handleReset={handleReset}
          />
        </div>
        {/* Clean state */}
        {isClean && (
          <div className="col-sm-12 col-md-9 empty-result-container">
            <h3>Enter readings to see interpretations</h3>
          </div>
        )}
        {/* Data is loading */}
        {!isClean && dgaLoading && (
          <div className="col-sm-12 col-md-9 empty-result-container">
            <h3>Analyzing Readings...</h3>
          </div>
        )}
        {/* Data is loaded */}
        {!isClean && !dgaLoading && (
          <div className="col-sm-12 col-md-9">
            <Results resultSet={dgaResults} />
          </div>
        )}
      </div>
    </>
  );
}

export default Calculator;
