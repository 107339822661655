export function debounce(callback, delay = 200) {
  let timer;
  return function debouncedCallback(...args) {
    const that = this;
    clearTimeout(timer);
    timer = setTimeout(() => {
      callback.apply(that, args);
    }, delay);
  };
}

export function throttle(callback, delay = 200) {
  let shouldThrottle;
  return function throttledCallback(...args) {
    const that = this;
    if (!shouldThrottle) {
      callback.apply(that, args);
      shouldThrottle = true;
      setTimeout(() => {
        shouldThrottle = false;
      }, delay);
    }
  };
}
