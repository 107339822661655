const validationRules = {
  required: {
    test: (input) => input.length > 0,
    errorMessage: 'Field required'
  },
  email: {
    test: (input) => {
      const validEmail = /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/;
      if (!input) {
        return true;
      }
      return validEmail.test(input);
    },
    errorMessage: 'Invalid email address'
  },
  number: {
    test: (input) => {
      const validNumber = /^([0-9]\d*(\.|,)\d*|0?(\.|,)\d*[1-9]\d*|[0-9]\d*)$/;
      if (!input) {
        return true;
      }
      return validNumber.test(input);
    },
    errorMessage: 'Must be a number'
  }
};

export default validationRules;
