import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3';

import { debounce } from '../../utils/timers';

import { createCalculator, hasGasValues } from './calculations';
import areas from './areas.json';
import axes from './axes.json';

import './duval-triangle.scss';

function DuvalTriangle({ width, gasPPMValues }) {
  const svgContainer = useRef();
  const [clientWidth, setClientWidth] = useState(Infinity);

  const validatedWidth = width < clientWidth ? width : clientWidth;
  const validatedHeight = width < clientWidth ? width : clientWidth;

  const margins =
    validatedWidth > 300
      ? { top: 0, right: 15, bottom: 25, left: 15 } // medium resolution +
      : { top: 0, right: 10, bottom: 20, left: 10 }; // small resolution

  // Set up our scales and init calculations
  const xScale = scaleLinear()
    .domain([0, 100])
    .range([margins.bottom, validatedWidth - margins.top]);
  const yScale = scaleLinear()
    .domain([0, 100])
    .range([margins.left, validatedHeight - margins.right]);
  const calc = createCalculator({ xScale, yScale });

  const mapGasAreaToPoints = (areaArray) =>
    areaArray.reduce(
      (accum, value) => `${calc.gasPercentageToCoordinateString(value)} ${accum}`,
      ''
    );

  useEffect(() => {
    const resizeHandler = debounce(() => {
      setClientWidth(svgContainer.current.getBoundingClientRect().width);
    }, 200);
    resizeHandler();
    // eslint-disable-next-line no-undef
    window.addEventListener('resize', resizeHandler);
    return () => {
      // eslint-disable-next-line no-undef
      window.removeEventListener('resize', resizeHandler);
    };
  }, []);

  return (
    <div ref={svgContainer}>
      <svg className="duval-triangle" width={validatedWidth} height={validatedHeight}>
        <g transform={`rotate(270) translate(-${validatedHeight} 0)`}>
          <g className="axis-labels">
            {/* Text */}
            <text
              x={calc.gasPPMtoXcoordinateString(axes.ch4Midpoint)}
              y={calc.gasPPMtoYcoordinateString(axes.ch4Midpoint)}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(32.5, ${calc.gasPPMtoCoordinateString(
                axes.ch4Midpoint
              )}) translate(0, -17)`}
            >
              % CH
              <tspan baselineShift="sub" className="sub">
                4
              </tspan>{' '}
              →
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h4Midpoint)}
              y={calc.gasPPMtoYcoordinateString(axes.c2h4Midpoint)}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(150, ${calc.gasPPMtoCoordinateString(
                axes.c2h4Midpoint
              )}) translate(0, -17)`}
            >
              % C
              <tspan baselineShift="sub" className="sub">
                2
              </tspan>
              H
              <tspan baselineShift="sub" className="sub">
                4
              </tspan>{' '}
              →
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h2Midpoint)}
              y={calc.gasPPMtoYcoordinateString(axes.c2h2Midpoint)}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(90, ${calc.gasPPMtoCoordinateString(
                axes.c2h2Midpoint
              )}) translate(0, 12)`}
            >
              ← % C
              <tspan baselineShift="sub" className="sub">
                2
              </tspan>
              H
              <tspan baselineShift="sub" className="sub">
                2
              </tspan>
            </text>
            {/* Numbers */}
            <text
              x={calc.gasPPMtoXcoordinateString(axes.ch4[0])}
              y={calc.gasPPMtoYcoordinateString(axes.ch4[0])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(32.5, ${calc.gasPPMtoCoordinateString(
                axes.ch4[0]
              )}) translate(10, -10)`}
              className="small"
            >
              0
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.ch4[1])}
              y={calc.gasPPMtoYcoordinateString(axes.ch4[1])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(32.5, ${calc.gasPPMtoCoordinateString(
                axes.ch4[1]
              )}) translate(-15, -10)`}
              className="small"
            >
              100
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h4[0])}
              y={calc.gasPPMtoYcoordinateString(axes.c2h4[0])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(150, ${calc.gasPPMtoCoordinateString(
                axes.c2h4[0]
              )}) translate(10, -10)`}
              className="small"
            >
              0
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h4[1])}
              y={calc.gasPPMtoYcoordinateString(axes.c2h4[1])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(150, ${calc.gasPPMtoCoordinateString(
                axes.c2h4[1]
              )}) translate(-15, -10)`}
              className="small"
            >
              100
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h2[0])}
              y={calc.gasPPMtoYcoordinateString(axes.c2h2[0])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(90, ${calc.gasPPMtoCoordinateString(
                axes.c2h2[0]
              )}) translate(10, 10)`}
              className="small"
            >
              100
            </text>
            <text
              x={calc.gasPPMtoXcoordinateString(axes.c2h2[1])}
              y={calc.gasPPMtoYcoordinateString(axes.c2h2[1])}
              dominantBaseline="middle"
              textAnchor="middle"
              transform={`rotate(90, ${calc.gasPPMtoCoordinateString(
                axes.c2h2[1]
              )}) translate(-5, 10)`}
              className="small"
            >
              0
            </text>
          </g>
          <g className="axes">
            <line
              x1={calc.gasPPMtoXcoordinateString(axes.ch4[0])}
              y1={calc.gasPPMtoYcoordinateString(axes.ch4[0])}
              x2={calc.gasPPMtoXcoordinateString(axes.ch4[1])}
              y2={calc.gasPPMtoYcoordinateString(axes.ch4[1])}
            />
            <line
              x1={calc.gasPPMtoXcoordinateString(axes.c2h4[0])}
              y1={calc.gasPPMtoYcoordinateString(axes.c2h4[0])}
              x2={calc.gasPPMtoXcoordinateString(axes.c2h4[1])}
              y2={calc.gasPPMtoYcoordinateString(axes.c2h4[1])}
            />
            <line
              x1={calc.gasPPMtoXcoordinateString(axes.c2h2[0])}
              y1={calc.gasPPMtoYcoordinateString(axes.c2h2[0])}
              x2={calc.gasPPMtoXcoordinateString(axes.c2h2[1])}
              y2={calc.gasPPMtoYcoordinateString(axes.c2h2[1])}
            />
          </g>
          <g className="areas">
            <polygon points={mapGasAreaToPoints(areas.pd)} className="pd" />
            <polygon points={mapGasAreaToPoints(areas.d1)} className="d1" />
            <polygon points={mapGasAreaToPoints(areas.d2)} className="d2" />
            <polygon points={mapGasAreaToPoints(areas.dt)} className="dt" />
            <polygon points={mapGasAreaToPoints(areas.t1)} className="t1" />
            <polygon points={mapGasAreaToPoints(areas.t2)} className="t2" />
            <polygon points={mapGasAreaToPoints(areas.t3)} className="t3" />
          </g>
          <g className="area-labels">
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.d1)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.d1)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.d1
              )}) translate(20, -10)`}
              className="d1"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              D1
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.d2)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.d2)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.d2
              )}) translate(20, -10)`}
              className="d2"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              D2
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.dt)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.dt)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.dt
              )}) translate(10, -10)`}
              className="dt"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              DT
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.t1)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.t1)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.t1
              )}) translate(5, -10)`}
              className="t1"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              T1
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.t2)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.t2)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.t2
              )}) translate(5, -10)`}
              className="t2"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              T2
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.t3)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.t3)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.t3
              )}) translate(10, -10)`}
              className="t3"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              T3
            </text>
            <text
              x={calc.gasPercentageToXCoordinateString(axes.labels.pd)}
              y={calc.gasPercentageToYCoordinateString(axes.labels.pd)}
              transform={`rotate(90, ${calc.gasPercentageToCoordinateString(
                axes.labels.pd
              )}) translate(0, -10)`}
              className="pd"
              dominantBaseline="middle"
              textAnchor="middle"
            >
              PD
            </text>
          </g>
          <g className="readings">
            {gasPPMValues.length > 0 &&
              gasPPMValues.map(
                (values, index) =>
                  hasGasValues(values.gases) && (
                    <g key={index}>
                      <circle
                        cx={calc.gasPPMtoXcoordinateString(values.gases)}
                        cy={calc.gasPPMtoYcoordinateString(values.gases)}
                        r="5"
                        fill="none"
                        stroke="#FF6347"
                        strokeWidth="5%"
                      >
                        <animate
                          attributeType="SVG"
                          attributeName="r"
                          begin="0s"
                          dur="1.5s"
                          repeatCount="indefinite"
                          from="1%"
                          to="10%"
                        />
                        <animate
                          attributeType="CSS"
                          attributeName="stroke-width"
                          begin="0s"
                          dur="1.5s"
                          repeatCount="indefinite"
                          from="3%"
                          to="0%"
                        />
                        <animate
                          attributeType="CSS"
                          attributeName="opacity"
                          begin="0s"
                          dur="1.5s"
                          repeatCount="indefinite"
                          from="0.5"
                          to="0"
                        />
                      </circle>
                      <circle
                        cx={calc.gasPPMtoXcoordinateString(values.gases)}
                        cy={calc.gasPPMtoYcoordinateString(values.gases)}
                        r="2"
                        className="plot-point"
                      />
                    </g>
                  )
              )}
          </g>
        </g>
      </svg>
    </div>
  );
}

DuvalTriangle.propTypes = {
  width: PropTypes.number,
  gasPPMValues: PropTypes.arrayOf(PropTypes.object)
};

DuvalTriangle.defaultProps = {
  width: 300,
  gasPPMValues: []
};

export default DuvalTriangle;
