import React from 'react';
import PropTypes from 'prop-types';

import './subheader.scss';

function SubHeader({ title, subtitle }) {
  return (
    <div className="subheader">
      <h1 className="subheader-title">
        {title}
        {subtitle && <small>{subtitle}</small>}
      </h1>
    </div>
  );
}

SubHeader.propTypes = {
  title: PropTypes.node,
  subtitle: PropTypes.string
};

export default SubHeader;
