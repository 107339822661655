const DEFAULT_OPTIONS = {
  credentials: 'same-origin',
  headers: {
    'Access-Control-Allow-Origin': 'http://localhost:55001'
  }
};

function http(requestAPI) {
  return async (options) => {
    try {
      const response = await requestAPI(options.url, {
        ...DEFAULT_OPTIONS,
        ...options
      });
      if (response.status >= 200 && response.status < 300) {
        return await response.json();
      }
      if (response.status >= 400 && response.status < 500) {
        // Handle these errors
        return await response.json();
      }
      throw new Error(`Http Request Error: ${response.status} ${response.message}`);
    } catch (error) {
      // TODO: handle error
      return error;
    }
  };
}

export default http;
