import React from 'react';
import PropTypes from 'prop-types';
import { isEmpty } from 'ramda';
import Panel from '../Panel';
import DuvalTriangle from '../DuvalTriangle';

import './result-set.scss';

function displayRatio(numerator, denominator) {
  if (numerator === undefined || Number.isNaN(Number(numerator))) {
    return 'n/a';
  }
  if (
    denominator === undefined ||
    Number(denominator) === 0 ||
    Number.isNaN(Number(denominator))
  ) {
    return 'n/a';
  }
  return `${(Number(numerator) / Number(denominator)).toFixed(4)}`;
}

function Results({ resultSet }) {
  return (
    <>
      <Panel
        content={
          resultSet &&
          !isEmpty(resultSet) && (
            <div className="result-set">
              <h3>Totals</h3>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>TDCG (total disolved combustible gases)</td>
                    <td>{`${resultSet.tdcg}`}</td>
                  </tr>
                </tbody>
              </table>
              <h3>Ratios</h3>
              <table className="table table-striped">
                <tbody>
                  <tr>
                    <td>
                      <span className="bold">Ratios according to Rogers (IEEE C57.104)</span>
                    </td>
                    <td>
                      <span className="bold">Diagnosis / Recommendation</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>2</sub> / C<sub>2</sub>H<sub>4</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H2, resultSet.gases.c2H4)}
                    </td>
                    <td>{resultSet.rogersRatios.diagnosisText}</td>
                  </tr>
                  <tr>
                    <td>
                      CH<sub>4</sub> / H<sub>2</sub> ={' '}
                      {displayRatio(resultSet.gases.cH4, resultSet.gases.h2)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>4</sub> / C<sub>2</sub>H<sub>6</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H4, resultSet.gases.c2H6)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="bold">
                        Ratios according to Doernenburg (IEEE C57.104)
                      </span>
                    </td>
                    <td>
                      <span className="bold">Diagnosis / Recommendation</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>2</sub> / C<sub>2</sub>H<sub>4</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H2, resultSet.gases.c2H4)}
                    </td>
                    <td>{resultSet.doernenburgRatios.diagnosisText}</td>
                  </tr>
                  <tr>
                    <td>
                      CH<sub>4</sub> / H<sub>2</sub> ={' '}
                      {displayRatio(resultSet.gases.cH4, resultSet.gases.h2)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>2</sub> / CH<sub>4</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H2, resultSet.gases.cH4)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>6</sub> / C<sub>2</sub>H<sub>2</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H6, resultSet.gases.c2H2)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="bold">
                        Ratios according to Basic Gas Ratios (IEC 60599)
                      </span>
                    </td>
                    <td>
                      <span className="bold">Diagnosis / Recommendation</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>2</sub> / C<sub>2</sub>H<sub>4</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H2, resultSet.gases.c2H4)}
                    </td>
                    <td>{resultSet.basisGasRatios.diagnosisText}</td>
                  </tr>
                  <tr>
                    <td>
                      CH<sub>4</sub> / H<sub>2</sub> ={' '}
                      {displayRatio(resultSet.gases.cH4, resultSet.gases.h2)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      C<sub>2</sub>H<sub>6</sub> / C<sub>2</sub>H<sub>2</sub> ={' '}
                      {displayRatio(resultSet.gases.c2H6, resultSet.gases.c2H2)}
                    </td>
                    <td>&nbsp;</td>
                  </tr>
                  <tr>
                    <td>
                      <span className="bold">
                        CO<sub>2</sub> / CO Ratio Analysis according to IEC 60599
                      </span>
                    </td>
                    <td>
                      <span className="bold">Diagnosis / Recommendation</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      CO<sub>2</sub> / CO ={' '}
                      {displayRatio(resultSet.gases.cO2, resultSet.gases.co)}
                    </td>
                    <td>{resultSet.cO2CORatio.diagnosisText}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          )
        }
      />
      <Panel
        content={
          resultSet &&
          !isEmpty(resultSet) && (
            <div className="result-set">
              <h3>Duval Triangle</h3>
              <DuvalTriangle height={400} width={400} gasPPMValues={[resultSet]} />
            </div>
          )
        }
      />
    </>
  );
}

Results.propTypes = {
  resultSet: PropTypes.object
};

export default Results;
